<template>
  <dryness-location-template
    :gender="gender"
    :facial-dryness-locations="facialDrynessLocations"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DrynessLocationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/dryness-location/DrynessLocationTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field, requiredField } = fieldBuilder;

const FIELDS = [field('gender'), requiredField('facialDrynessLocations')];

export default {
  name: 'DrynessLocation',
  components: {
    DrynessLocationTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    facialDrynessLocations() {
      this.showNextStep();
    }
  }
};
</script>
